import { useState } from "react";

import Button from "../components/Button";
import Header from "../components/Header";
import { useHistory } from "react-router-dom";
import { updateSession } from "../services/sessionService";
import { createMail } from "../services/mailService";
import Error from "../components/Error";
import TagManager from "react-gtm-module";

const MailPage = (props) => {

    TagManager.dataLayer({
        dataLayer: {
            event: 'pageview',
            pagePath: 'email',
            pageTitle: 'ontvang documentatie',
        },
    });

    const [data, setData] = useState({
        zipCode: "",
        email: "",
    });
    const [errors, setErrors] = useState({});
    const history = useHistory();
    const scores = props.location.state.scores;
    const sessionId = props.location.state.sessionId;

    const handleChange = (newData) => {
        setData({
            ...data,
            [newData.target.name]: newData.target.value,
        });
    };

    function handleSendMail() {
        let valid = true;
        const formErrors = {};

        if (data.zipCode === "") {
            valid = false;
            formErrors.zipCode = "Postcode is een verplicht veld.";
        }

        if (data.email === "") {
            valid = false;
            formErrors.email = "E-mailadres is een verplicht veld.";
        }

        setErrors(formErrors);

        if (valid) {
            patchSession(data);
            sendMail();
        }
    }

    function patchSession(data) {
        updateSession(sessionId, data);
    }

    function sendMail() {
        var result = [];
        for (let i = 0; i < scores.length; i++) {
            result.push({
                name: scores[i].thema,
                score: scores[i].score,
                todo: scores[i].todo,
            });
        }
        var mail = {
            email: data.email,
            result: result,
        };

        createMail(mail).then((resp) => {
            if (resp.data) {
                history.push("/bedankt", resp.data);
            }
        });
    }

    return (
        <div className="page">
            <Header pageTitle="Uw" pageSubtitle="uitslag" />

            <div className="page__content">
                <div className="container">
                    <h2>Bezorg mij deze informatie</h2>
                    <p>
                        Wij sturen je een volledig overzicht van je checklist en
                        de nodige extra documentatie.
                    </p>

                    <form>
                        <div className="formInput formInput--text margin-bottom">
                            <label htmlFor="zipCode">Postcode</label>
                            <input
                                type="text"
                                name="zipCode"
                                id="zipCode"
                                onChange={handleChange}
                            />
                            {errors.zipCode && <Error>{errors.zipCode}</Error>}
                        </div>

                        <div className="formInput formInput--text margin-bottom">
                            <label htmlFor="email">E-mailadres</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                onChange={handleChange}
                            />
                            {errors.email && <Error>{errors.email}</Error>}
                        </div>
                    </form>
                    <Button
                        theme="primary"
                        type="button"
                        onClick={handleSendMail}
                    >
                        Verzenden
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default MailPage;
