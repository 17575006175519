import styled, { css } from 'styled-components';

export const TooltipWrapper = styled.div`
    position: relative;

    ${props =>
        props.open &&
        css`
            ${TooltipIcon} {
                background-color: #CD163F;
                color: white;
            }

            ${TooltipText} {
                display: block;
            }
        `}
`;

export const TooltipIcon = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #CD163F;
    color: #CD163F;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const TooltipText = styled.span`
    position: absolute;
    background-color: white;
    color: black;
    border: 2px solid #CD163F;
    border-radius: 5px;
    padding: 10px;
    bottom: 10px;
    right: 0;
    display: none;
    overflow-y: scroll;
    height: 115px;
    font-size: 13px;
    line-height: 22px;
    font-weight: 400;
`;