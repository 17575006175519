import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.button`
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 23px;
    border: none;
    text-decoration: none;
    cursor: pointer;

    ${props =>
        props.primary &&
        css`
            background-color: #40AC49;
            color: white;
        `}
        ${props =>
        props.lightgreen &&
        css`
                background-color: #EAF9EB;
                color: #40AC49;
            `}
    ${props =>
        props.outlined &&
        css`
            background-color: transparent;
            color: white;
            border: 1px solid #F0ADBC;
        `}

    ${props =>
        props.transparent &&
        css`
            background-color: transparent;
            color: #40AC49;
            margin-top: 0px;
            line-height: 80px;
            border: 1px solid #40AC49;
        `}

    ${props =>
        props.link &&
        css`
                background-color: transparent;
                color: #40AC49;
                margin-top: 0px;
                line-height: 80px;
        `}
    
`;