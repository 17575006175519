import { FaCheck } from "react-icons/fa";
import Tooltip from "./Tooltip";

const ThemeForm = ({ themes, updateData, answers, tooltip }) => {
    var obj = Object.values(themes);
    obj.pop();
    obj.pop();
    return (
        <form>
            <div className="formInput formInput--checkbox margin-bottom-xlg">
                {!tooltip ? null : (
                    <Tooltip>{tooltip}</Tooltip>
                )
                }
                <p>Vink aan wat in uw zaak van toepassing is.</p>
                {obj.map((answer) => (
                    <label key={answer.id} htmlFor={answer.id}>
                        {answer.title}
                        <input
                            type="checkbox"
                            value={answer.question}
                            name={answer.id}
                            id={answer.id}
                            onChange={updateData}
                            checked={answers ? answers[answer.id] : false}
                        />
                        <span className="checkmark">
                            <FaCheck />
                        </span>
                    </label>
                ))}
            </div>
        </form>
    );
};

export default ThemeForm;
