import Flame from './Flame'
import PageTitle from './PageTitle'
import ProgressBar from "./ProgressBar"
import { FaArrowCircleLeft } from 'react-icons/fa';
const Header = ({ pageTitle, pageSubtitle, currentStep, steps, backButton }) => {
    if (backButton) {
        backButton = <span onClick={event => window.location.href = '/'} ><FaArrowCircleLeft className={'backBtn'} /></span>
    }
    else {
        backButton = ""
    }
    return (
        <>
            <header className="header">
                <PageTitle title={pageTitle} subTitle={pageSubtitle} />
                {backButton}
                <Flame currentStep={currentStep} steps={steps} />
            </header>

            <ProgressBar current={currentStep} max={steps} />
        </>
    )
}

export default Header
