import { LoadingWrapper, LoadingImage } from "./LoadingElements"

const Loading = () => {
    return (
        <LoadingWrapper>
            <LoadingImage src="../images/logo.svg" alt="Brandweer Zone Rand Logo"></LoadingImage>
        </LoadingWrapper>
    )
}

export default Loading
