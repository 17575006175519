import Header from "../components/Header";

const ContactPage = () => {
    return (
        <div className="page">
            <Header pageTitle="Contact" backButton="back" />

            <div className="page__content">
                <div className="container">
                    <h2>Contact </h2>
                    <p>
                        Om een brandpreventieadvies of brandpreventieverslag
                        (controlebezoek) aan te vragen kan je gebruik maken van
                        de webformulieren op de{" "}
                        <a href="https://www.brandweerzonerand.be">
                            website
                        </a>{" "}
                        van Brandweer Zone Rand.{" "}
                    </p>
                    <p>
                        Algemene vragen kan je mailen naar{" "}
                        <a href="mailto: ikcheckmijnzaak@brandweer.zonerand.be">
                            ikcheckmijnzaak@brandweer.zonerand.be
                        </a>{" "}
                        of gebruik maken van ons{" "}
                        <a href="https://www.brandweerzonerand.be/aanvraag-controle-brandveiligheid">
                            contactformulier
                        </a>
                        .
                    </p>
                    <p>
                        Onze medewerkers behandelen deze aanvragen zo snel als
                        mogelijk.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
