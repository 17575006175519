import styled, { css } from "styled-components";

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    overflox: hidden;
    z-index: 1;
    display: none;

    ${props =>
        props.show &&
        css`
            display: block;
        `}
`;

export const StyledModal = styled.div`
    position: fixed;
    top: 40px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    border-radius: 8px 8px 0 0;
    padding: 70px 30px;
    z-index: 2;
`;

export const ModalMain = styled.div`
    overflow: scroll;
    height: 100%;
`;

export const ModalFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 30px;
    background-color: white;
    box-shadow: 0 -5px 10px 0 rgba(45,55,72,0.1);
`;

export const ModalClose = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid #CD163F;
    color: #CD163F;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;