import { Link } from "react-router-dom";
import ScoreBar from "../ScoreBar";
import { ResultWrapper } from "./ResultElements";

const Result = ({ title, score, todos, state }) => {
    return (
        <ResultWrapper>
            <h3>{title}</h3>
            <ScoreBar score={score} />

            {score < 100 ? (
                <Link
                    to={{
                        pathname: "mijn-todos",
                        state: { thema: title, todos: todos, state: state },
                    }}
                    className={"boe"}
                    label="Actiepunten"
                >
                    Actiepunten
                </Link>
            ) : (
                <p>Goed bezig! Er zijn geen actiepunten voor dit onderdeel.</p>
            )}
        </ResultWrapper>
    );
};

export default Result;
