import { ButtonWrapper } from "./ButtonElements"

const Button = ({ children, theme, classes, onClick }) => {
    return (
        <ButtonWrapper
            onClick={onClick}
            className={classes}
            primary={theme === "primary"}
            lightgreen={theme === "lightgreen"}
            outlined={theme === "outlined"}
            transparent={theme === "transparent"}
            link={theme === "link"}
        >
            {children}
        </ButtonWrapper>
    )
}

export default Button