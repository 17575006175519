import { Link } from "react-router-dom";
import Header from "../components/Header";

const ToDoPage = (props) => {
    const thema = props.location.state.thema;
    const todos = props.location.state.todos;

    return (
        <div className="page">
            <Header pageTitle="Mijn" pageSubtitle="actiepunten" />

            <div className="page__content">
                <div className="container">
                    <h2>{thema}</h2>

                    <p>
                        Onderstaande werkpunten zijn nog op te nemen.
                        <br />
                        De kleuraanduiding geeft aan hoe dringend deze
                        actiepunten moeten worden aangepakt.
                    </p>

                    <form>
                        <div className="formInput formInput--checkbox margin-bottom-xlg">
                            {todos.map((todo) => (
                                <label
                                    key={todo.id}
                                    htmlFor={todo.id}
                                    className={
                                        "weight_" +
                                        todo.weight +
                                        " factor" +
                                        todo.value
                                    }
                                >
                                    {todo.title}
                                    <input
                                        type="checkbox"
                                        name={todo.id}
                                        id={todo.id}
                                    />
                                </label>
                            ))}
                        </div>
                    </form>

                    <Link
                        to={{
                            pathname: "uw-uitslag",
                            state: { state: props.location.state.state },
                        }}
                        className="btn"
                    >
                        Terug naar overzicht
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ToDoPage;
