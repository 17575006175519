import { useState } from "react";

import Button from "../components/Button";
import Header from "../components/Header";
import { useHistory } from "react-router-dom";
import { updateSession } from "../services/sessionService";
import { createAppointment } from "../services/appointmentService";
import Error from "../components/Error";

const AppointmentPage = (props) => {
    const [data, setData] = useState({
        company: "",
        zipCode: "",
        email: "",
        phone: "",
    });
    const history = useHistory();
    const scores = props.location.state.scores;
    const sessionId = props.location.state.sessionId;
    const [errors, setErrors] = useState({});

    const handleChange = (newData) => {
        setData({
            ...data,
            [newData.target.name]: newData.target.value,
        });
    };

    function handleSendMail() {
        let valid = true;
        const formErrors = {};

        if (data.company === "") {
            valid = false;
            formErrors.company = "Uw naam of firma is een verplicht veld.";
        }

        if (data.zipCode === "") {
            valid = false;
            formErrors.zipCode = "Postcode is een verplicht veld.";
        }

        if (data.email === "") {
            valid = false;
            formErrors.email = "E-mailadres is een verplicht veld.";
        }

        if (data.phone === "") {
            valid = false;
            formErrors.phone = "Telefoonnummer is een verplicht veld.";
        }

        setErrors(formErrors);

        if (valid) {
            patchSession(data);
            sendMail();
        }
    }

    function patchSession(data) {
        updateSession(sessionId, data);
    }

    function sendMail() {
        var result = [];
        for (let i = 0; i < scores.length; i++) {
            result.push({
                name: scores[i].thema,
                score: scores[i].score,
            });
        }
        var mail = {
            email: data.email,
            zipCode: data.zipCode,
            company: data.company,
            phone: data.phone,
            result: result,
        };

        createAppointment(mail).then((resp) => {
            if (resp.data) {
                history.push("/bedankt", resp.data);
            }
        });
    }

    return (
        <div className="page">
            <Header pageTitle="Afspraak" pageSubtitle="maken" />

            <div className="page__content">
                <div className="container">
                    <h2>Contacteer mij voor een afspraak</h2>
                    <p>
                        Geef hieronder je contactgegevens op en wij contacteren
                        je ze snel mogelijk
                    </p>

                    <form>
                        <div className="formInput formInput--text margin-bottom">
                            <label htmlFor="company">Uw naam of firma</label>
                            <input
                                type="text"
                                name="company"
                                id="company"
                                onChange={handleChange}
                            />
                            {errors.company && <Error>{errors.company}</Error>}
                        </div>

                        <div className="formInput formInput--text margin-bottom">
                            <label htmlFor="zipCode">Postcode</label>
                            <input
                                type="text"
                                name="zipCode"
                                id="zipCode"
                                onChange={handleChange}
                            />
                            {errors.zipCode && <Error>{errors.zipCode}</Error>}
                        </div>

                        <div className="formInput formInput--text margin-bottom">
                            <label htmlFor="email">E-mailadres</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                onChange={handleChange}
                            />
                            {errors.email && <Error>{errors.email}</Error>}
                        </div>

                        <div className="formInput formInput--text margin-bottom-lg">
                            <label htmlFor="phone">Telefoonnummer</label>
                            <input
                                type="phone"
                                name="phone"
                                id="phone"
                                onChange={handleChange}
                            />
                            {errors.phone && <Error>{errors.phone}</Error>}
                        </div>
                    </form>
                    <Button
                        theme="primary"
                        type="button"
                        onClick={handleSendMail}
                    >
                        Verzenden
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AppointmentPage;
