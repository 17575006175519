import Header from "../components/Header";
import Accordion from "../components/Accordion/Accordion";

const FaqPage = () => {
    return (
        <div className="page">
            <Header pageTitle="FAQ" backButton="back" />

            <div className="page__content">
                <div className="container">
                    <h1 className="accordion-section">Algemeen</h1>
                    <Accordion
                        title="Waarom dit reglement?"
                        content="<p>
                        “Brandweer Zone Rand telt 21 gemeenten die de Belgische regels elk op hun eigen manier hadden uitgewerkt”. Dat was verwarrend voor eigenaars en uitbaters. <br/>
                        De brandweer moest op basis van verschillende regels een ander advies geven. <br/>
                        Met één reglement willen we een gelijke bescherming voor alle inwoners van Brandweer Zone Rand én duidelijk maken wat je als uitbater moet doen om een brandveilige zaak uit te baten.
                    </p>"
                    />
                    <Accordion
                        title="In welke gemeente is dit reglement van toepassing?"
                        content="<p>
                        We verwachten dat op korte termijn elke gemeente in Brandweer Zone Rand dit reglement zal goedkeuren. Dit is al het geval voor de meerderheid van de gemeenten in onze zone.
                        <br/><br/>
                        In <a target='blank' href='https://www.brandweerzonerand.be/ikcheckmijnzaak#inwerking'>deze tabel</a> kan je nagaan wanneer het reglement werd goedgekeurd op de gemeenteraad van jouw gemeente en wanneer het in werking is getreden.
                        <br/><br/>
                        Indien het reglement nog niet werd bekrachtigd in de Gemeenteraad en er geen bestaand politiereglement van toepassing is, dan hanteert de brandweer het reglement als leidraad bij de opmaak van een brandpreventieadvies. Het reglement vormt een Code van Goede praktijk.
                    </p>"
                    />
                    <Accordion
                        title="Welke inrichtingen vallen onder dit politiereglement?"
                        content="<p>
                        De bepalingen zijn van toepassing op alle publiek toegankelijke inrichtingen.
                        <br/><br/>
                        Deze bepalingen gelden eveneens voor inrichtingen waar het publiek slechts onder bepaalde voorwaarden wordt toegelaten. D.w.z. bij een persoonlijke uitnodiging, op vertoon van lidkaart of indien dient betaald te worden om toegang te verkrijgen.
                    </p>"
                    />
                    <Accordion
                        title="Welke inrichtingen vallen niet onder dit politiereglement?"
                        content="<p>Dit reglement is niet van toepassing op:</p>
                    <ul>

                        <li>Gebouwen waar een specifieke reglementering voor de brandveiligheid van toepassing is, bijvoorbeeld ziekenhuizen, toeristische logies, ouderenvoorzieningen, voetbalstadion, ...</li>
                        <li>Kantoren of delen van kantoren die niet toegankelijk zijn voor het publiek</li>
                        <li>Scholen erkend door het Agentschap Onderwijs en Vorming</li>
                        <li>Parkeergebouwen</li>
                        <li>Gebouwen voor de uitoefening van erediensten tijdens het de uitoefenen van een eredienst.</li>
                        <li>Lokalen of gebouwen die niet werden ontworpen als publiek toegankelijke inrichting en die maximum 2x per jaar voor een periode van maximum 5 dagen worden opengesteld voor publiek</li>
                        <li>Installaties in open lucht en uitbatingen in tijdelijke constructies, zoals tenten, marktkramen en kermissen</li>
                    </ul>
                    <p>Het reglement kan steeds toegepast worden als Code van goede praktijk.</p>"
                    />
                    <Accordion
                        title="Welke delen van mijn zaak vallen onder het reglement?"
                        content="<p>
                        Alle ruimten die in functie staan van het publiek toegankelijke gedeelte vallen binnen het toepassingsgebied van het reglement. Hiertoe behoren o.a. kitchenettes, keukens, bergingen, technische ruimtes, …
                        <br/><br/>
                        Het privé woongedeelte van de exploitant maakt deel uit van de aanhorigheden, tenzij het  brandwerend gecompartimenteerd is (wanden EI60, en deuren EI₁30).
                    </p>"
                    />
                    <Accordion
                        title="Mijn zaak bestaat al een hele tijd, moet ik ook voldoen aan dit reglement?"
                        content="<p>
                        Ja, het reglement is ook van toepassing op bestaande publiek toegankelijke inrichtingen. <br/>
                        Er is een overgangsregeling voorzien voor bestaande zaken welke niet uitbreiden,  verbouwen of het aantal toegelaten personen verhogen. <br/>
                        Bestaande inrichtingen van categorie 2 en 3 moeten beschikken over een brandveiligheidsattest binnen een periode van 10 jaar na de <a target='blank' href='https://www.brandweerzonerand.be/ikcheckmijnzaak#inwerking'>inwerkingtreding</a> van dit reglement.
                        <br/><br/>
                        <b>Opmerking:</b><br/>
                        Brandweer Zone Rand adviseert de exploitant van een bestaande inrichting tijdig de nodige maatregelen te nemen om zijn zaak brandveilig te maken. <br/>
                        Hiervoor kan steeds een
                        <a target='blank' href='https://www.brandweerzonerand.be/content/contactformulier-preventie'>
                        overleg
                </a> met de brandweer aangevraagd worden.
                    </p>"
                    />
                    <Accordion
                        title="Waar vind ik de volledige tekst van dit reglement?"
                        content="<p>
                        De ‘Politieverordening houdende maatregelen tot het voorkomen en bestrijden van een brand in publiek toegankelijke inrichtingen’ zoals de officiële benaming luidt vind je <a target='blank' href='https://www.brandweerzonerand.be/ikcheckmijnzaak#reglement'>hier</a>. <br/>
                        Bovendien kan je ook een versie terugvinden aangevuld met <a target='blank' href='https://www.brandweerzonerand.be/ikcheckmijnzaak#reglement'>toelichtingen</a> welke het reglement verduidelijken
                    </p>"
                    />
                    <h1 className="accordion-section">Controles en attesten</h1>

                    <Accordion
                        title="Is het hebben van een brandveiligheidsattest verplicht?"
                        content="<p>
                        Ja. Publiek toegankelijke inrichtingen van categorie 2 en 3 moeten over een brandveiligheidsattest beschikken. <br/>
                        Voor de bestaande zaken is dit verplicht binnen de 10 jaar na inwerking treden van het reglement.
                        <br/><br/>
                        De categorie 1 instellingen moeten voldoen aan de voorwaarden van het reglement maar moeten niet beschikken over een attest. <br/>
                        Indien de inrichting niet voldoet aan deze voorwaarden kan deze niet uitgebaat worden. Dit is de verantwoordelijkheid van de exploitant.
                    </p>"
                    />
                    <Accordion
                        title="Wie levert het brandveiligheidsattest af (categorie 2 of 3)?"
                        content="<p>
                        Het brandpreventieverslag wordt afgeleverd door de burgemeester van de gemeente waar de zaak gelegen is. <br/>
                        De burgemeester beslist welk attest hij aflevert op basis van een brandpreventieverslag opgesteld door brandweer Zone Rand.
                    </p>"
                    />
                    <Accordion
                        title="Is een controlebezoek door de brandweer verplicht?"
                        content="<p>
                        Ja, inrichtingen van categorie 2 en 3 moeten beschikken over een brandveiligheidsattest. <br/>
                        Dit attest kan alleen worden uitgereikt na opmaak van een brandpreventieverslag door de brandweer. Hiervoor wordt altijd een controlebezoek uitgevoerd door een medewerker van brandweer Zone Rand.
                        <br/><br/>
                        Inrichtingen van categorie 1 moeten niet beschikken over een brandveiligheidsattest maar moeten natuurlijk wel voldoen aan de voorwaarden opgenomen in dit reglement.
                    </p>"
                    />
                    <Accordion
                        title="Hoe vraag ik een controlebezoek van de brandweer aan?"
                        content="<p>
                        Het controlebezoek kan aangevraagd worden via de website van Brandweer Zone Rand. Na invullen van het
                        <a target='blank' href='https://www.brandweerzonerand.be/aanvraag-controle-brandveiligheid'>
                        webformulier
                </a> zal een medewerker binnen de 10 dagen contact opnemen om een afspraak in te plannen.
                        <br/><br/>
                        Hou rekening met een doorlooptijd van minimaal 6 weken alvorens u het brandpreventieverslag ontvangt.
                        <br/><br/>
                        Opgelet ! <br/>
                        Er is een retributiereglement van toepassing op uw aanvraag waardoor mogelijks een factuur volgt. <br/>
                        Meer info kan u hierover terug vinden op de
                        <a target='blank' href='https://www.brandweerzonerand.be/retributie'>
                        website
                </a> van brandweer Zone Rand .
                    </p>"
                    />
                    <Accordion
                        title="Zijn er kosten verbonden aan het controlebezoek door de brandweer?"
                        content="<p>
                        Een controle door de brandweer is niet gratis. <br/>
                        De kostprijs is door de Zoneraad, waarin alle burgemeesters van Brandweer Zone Rand vertegenwoordigd zijn, vastgelegd in een
                        <a target='blank' href='https://www.brandweerzonerand.be/retributie'>
                        retributieregelement
                </a>.
                        <br/><br/>
                        Gemiddeld neemt de verplaatsing, het uitvoeren van het controlebezoek, nazicht van het veiligheidsregister en opmaken van het  brandpreventieverslag 2 uur in beslag. <br/>
                        Reken hierbij de verplichte administratieve kosten voor de opmaak en verwerking van het dossier en u kan een factuur verwachten van ca. 200 euro.
                    </p>"
                    />
                    <Accordion
                        title="Hoe hou ik de kosten verbonden aan het controlebezoek door de brandweer zo laag mogelijk?"
                        content="<p>
                        Door het grondig voor te bereiden van het controlebezoek kan u de bijbehorende kosten beperken.
                        <br/><br/>
                        Via de online tool <a target='blank' href='https://www.ikcheckmijnzaak.be'>www.ikcheckmijnzaak.be</a> krijg je een eerste indicatie van het veiligheidsniveau van je zaak. <br/>
                        Het reglement waar uw zaak aan moet voldoen kan je hier integraal terug vinden. Tevens zijn er toelichtingen beschikbaar welke duiding geven bij het <a target='blank' href='https://www.brandweerzonerand.be/ikcheckmijnzaak#reglement'>reglement</a>. <br/>
                        Je kan je steeds laten bijstaan door een bevoegd persoon.
                        <br/><br/>
                        Brandweer Zone Rand is beschikbaar voor vragen of toelichting bij het reglement. <br/>
                        (Hou er rekening mee dat een geschreven advies ook binnen de opdrachten van het retributiereglement valt).
                        <br/><br/>
                        Kijk zeker na of er een veiligheidsregister aanwezig is én of alle keuringsverslagen aanwezig en nog steeds geldig zijn. <br/>
                        Een <a target='blank' href='https://www.brandweerzonerand.be/sites/default/files/bijlages/template_veiligheidsregister.docx'>sjabloon</a> om een register op te maken kan u hier downloaden.
                        <br/><br/>
                        Vraag het controlebezoek pas aan nadat alle mogelijke tekortkomingen zijn weggewerkt. Zo hoeft er geen tweede controle met bijbehorende kosten plaats te vinden.
                    </p>"
                    />
                    <Accordion
                        title="Hoeveel tijd heb ik om een controlebezoek aan te vragen?"
                        content="<p>
                        De brandweer adviseert om 60 dagen voor uw zaak open gaat, de geldigheidsduur van een attest vervalt of u een nieuw attest nodig hebt, een aanvraag te doen.
                        <br/><br/>
                        De persoon die de controle zal uitvoeren contacteert u binnen 10 dagen om een afspraak in te plannen. Het brandpreventieverslag wordt na het controlebezoek bezorgd aan de exploitant en burgemeester. <br/>
                        De burgemeester levert daarna een attest af.
                        <br/><br/>
                        Let erop dat alle verbouwingswerken zijn afgerond alvorens het controlebezoek te laten plaatsvinden.
                    </p>"
                    />
                    <Accordion
                        title="Welke brandveiligheidsattesten zijn er?"
                        content="<p>Op het einde van de controle wordt door de brandweer een brandweerverslag opgemaakt.
                        Het brandweerverslag bevat:
                    </p>
                    <ul>

                        <li>een beschrijving van de bestaande toestand</li>
                        <li>een opsomming van de eventueel vastgestelde inbreuken</li>
                        <li>de voorwaarden waaraan de exploitant moet voldoen om in overeenstemming te zijn met deze reglementering</li>
                    </ul>
                    <p>
                        Op basis van dit brandweerverslag wordt, voor categorie 2 en 3, een brandveiligheidsattest afgeleverd door de burgemeester.
                        <br/><br/>
                        Als uit het verslag van de brandweer blijkt dat de inrichting voldoet aan de brandveiligheidsnormen kan de burgemeester een <b>brandveiligheidsattest A</b> uitreiken. Wijzigt er niets aan uw zaak en blijft u exploitant dan heeft dit attest een geldigheidsduur van 10 jaar.
                        <br/><br/>
                        Als uit het verslag van de brandweer blijkt dat de inrichting niet volledig voldoet aan de brandveiligheidsnormen, maar dat de veiligheid van het personeel en de bezoekers niet ernstig in gevaar komt, kan de burgemeester een <b>brandveiligheidsattest B</b> uitreiken. <br/>
                        De burgemeester bepaalt de geldigheidsduur van het attest B, met een maximale geldigheidsduur van 5 jaar.
                        <br/><br/>
                        Als uit het verslag van de brandweer blijkt dat de inrichting niet voldoet aan de brandveiligheidsnormen en hierdoor de veiligheid van personeel en bezoekers in gevaar komt of als de inrichting gedurende 5 jaar in het bezit is van een B attest zonder daarbij afdoende gevolg te geven aan de gemaakte opmerkingen kan de burgemeester  een  <b>brandveiligheidsattest C</b> uitreiken. <br/>
                        In dit geval kan de uitbating van de inrichting niet starten of verder doorgaan.
                    </p>"
                    />
                    <Accordion
                        title="Wat na het ontvangen van een A-attest?"
                        content="<p>
                        Proficiat, uw zaak voldoet aan de voorwaarden opgenomen in het reglement. <br/>
                        In principe heeft het A-attest een geldigheidsduur van 10 jaar. <br/>
                        Opgelet: de geldigheid van de keuringsattesten in het veiligheidsregister is op te volgen gedurende deze periode.
                        <br/><br/>
                        Een nieuw controlebezoek door de brandweer moet aangevraagd worden:
                    </p>
                    <ul>
                        <li>60 dagen voor het verlopen van het verkregen A-attest</li>
                        <li>bij een wijziging van exploitant</li>
                        <li>bij verbouwen, herinrichten of uitbreiden van uw zaak</li>
                        <li>Bij aanpassen van het aantal toegelaten personen</li>
                        <li>bij aanpassingen aan de evacuatiemogelijkheden (uitgangen en nooduitgangen)</li>
                    </ul>"
                    />
                    <Accordion
                        title="Wat na het ontvangen van een B-attest?"
                        content="<p>
                        Het brandpreventieverslag vermeldt een aantal tekortkomingen. <br/>
                        Gelukkig blijft de veiligheid van de bezoekers en personeel gegarandeerd. U kan uw activiteiten voortzetten gedurende de geldigheidsduur van het attest (max. 5 jaar).
                        <br/><br/>
                        Binnen deze geldigheidsduur moet u de vastgestelde tekortkomingen wegwerken.
                        <br/><br/>
                        Binnen de 6 maanden na het uitreiken van het B attest dient u een stappenplan te bezorgen aan de burgemeester, een template voor het opmaken van een stappenplan kan u <a target='blank' href='https://www.brandweerzonerand.be/sites/default/files/bijlages/template_stappenplan.xlsx'> hier</a> downloaden. In dit stappenplan geeft u aan hoe en op welke termijn u de tekortkomingen zal aanpakken. <br/>
                        De burgemeester kan dit stappenplan aan de brandweer voorleggen ter advies.
                        <br/><br/>
                        Na uitvoering van de aanpassingswerken of uiterlijk 60 dagen voor het verstrijken van de geldigheidsduur van het B-attest vraagt u de brandweer een nieuw controlebezoek in te plannen. Op basis van dit bezoek en de door u aangeleverde informatie wordt een aangepast brandpreventieverslag opgemaakt en bezorgd aan de burgemeester.
                        <br/><br/>
                        Indien de inrichting nu voldoet aan de brandveiligheidsnormen, kan de burgemeester een brandveiligheidsattest A uitreiken. <br/>
                        Indien (nog) niet alle aanpassingswerken werden uitgevoerd, kan de burgemeester het brandveiligheidsattest B verlengen op voorwaarde dat de maximale geldigheidsduur van 5 jaar nog niet werd overschreden. <br/>
                        Bij overschrijden van de maximale geldigheidsduur van 5 jaar kan de burgemeester uitzonderlijk het brandveiligheidsattest B verlengen mits verscherpte periodieke controles door de brandweer worden uitgevoerd. De bijhorende retributie is ten laste van de exploitant.
                    </p>"
                    />
                    <Accordion
                        title="Wat na het ontvangen van een C-attest?"
                        content="<p>
                        Een C attest toont aan dat uw zaak niet voldoet aan de brandveiligheidsnormen opgenomen in het reglement én dat de veiligheid van personeel en bezoekers in gevaar komt. <br/>
                        De uitbating van uw inrichting wordt onmiddellijk stopgezet. <br/>
                        Van zodra de tekortkomingen zijn weggewerkt kan een nieuw controlebezoek van de brandweer worden aangevraagd. Op basis hiervan kan een A of B attest worden uitgereikt.
                        <br/><br/>
                        Een C-attest wordt ook uitgereikt indien uw zaak gedurende 5 jaar in het bezit is van een B-attest zonder dat daarbij afdoende gevolg gegeven is aan de opmerkingen van de brandweer.
                    </p>"
                    />
                    <Accordion
                        title="Wat als ik niet kan voldoen aan alle regels?"
                        content="<p>
                        Als u, om welke reden dan ook, niet kan voldoen aan één van de bepalingen uit het reglement kan u hiervoor een afwijking aanvragen.
                        <br/><br/>
                        De afwijking wordt schriftelijk aangevraagd aan de burgemeester en omvat minstens volgende zaken:
                    </p>
                    <ul>
                        <li>duidelijke plannen van de zaak (getekend op schaal)</li>
                        <li>verklarende nota waarin wordt aangegeven:
                            <ul style='padding-top: 20px'>
                                <li>op welke artikelen een afwijking wordt aangevraagd</li>
                                <li>waarom er aan deze artikelen niet kan voldaan worden</li>
                                <li>welke alternatieve maatregelen genomen worden zodat een gelijkwaardig veiligheidsniveau kan bereikt worden</li>
                            </ul>
                        </li>

                    </ul>
                    <p>
                        Binnen de 6 maanden na de aanvraag beslist de burgemeester of, en onder welke voorwaarden, hij de afwijking toestaat.
                        <br/><br/>
                        Bij het nemen van deze beslissing kan de burgemeester zich laten bijstaan door de afwijkingscommissie.
                    </p>"
                    />
                    <Accordion
                        title="Er is een controle van de brandweer geweest, maar ik heb nog steeds mijn brandpreventieverslag of attest niet ontvangen. Tot wie kan ik mij wenden?"
                        content="<p>
                        U kan steeds informeren naar het verwachte brandpreventieverslag op <a target='blank' href='mailto: ikcheckmijnzaak@brandweer.zonerand.be'>ikcheckmijnzaak@brandweer.zonerand.be</a>. De administratie zal uw vraag zo snel mogelijk behandelen. Indien nodig wordt u in contact gebracht met de dossierbehandelaar.
                        <br/><br/>
                        Een brandveiligheidsattest wordt opgemaakt door de burgemeester. Indien u het brandpreventieverslag ontvangen hebt kan u rechtstreeks contact nemen met de diensten van de burgemeester van de gemeente waar de zaak gelegen is. <br/>
                        Hiervoor kan u niet bij de brandweer terecht.
                    </p>"
                    />
                    <h1 className="accordion-section">
                        Vragen over het reglement
                    </h1>
                    <Accordion
                        title="Hoe bepaal ik de categorie waartoe mijn zaak behoort?"
                        content="<p>De categorie wordt bepaald door het aantal toegelaten personen in de zaak.</p>
                    <ul>
                        <li>categorie 1: maximum 9 personen toegelaten</li>
                        <li>categorie 2: minimum 10 en maximum 49 personen toegelaten</li>
                        <li>categorie 3: 50 personen of meer toegelaten</li>
                    </ul>
                    <p>
                        De exploitant stelt op eigen verantwoordelijkheid het aantal toegelaten personen vast binnen de regels van het reglement. <br/>
                        Hoe hoger het aantal toegelaten personen, hoe hoger de categorie en hoe strenger de voorwaarden opgenomen in het reglement.
                    </p>"
                    />
                    <a name='personen'></a>
                    <Accordion
                        title="Hoe bepaal ik het aantal toegelaten personen?"
                        content="<p>
                        Om het aantal toegelaten personen te bepalen moet eerst een minimum en een maximum aantal toegelaten personen berekend worden.
                        Hiertussen bepaalt de exploitant zelf hoeveel personen hij wil toelaten in zijn zaak.

                        De berekening van het aantal toegelaten personen gebeurt in functie van de oppervlakte van de zaak, de aanwezige uitgangen en de bestemming. <br/>
                        De bepalingen kan u terugvinden in het reglement onder artikel B2.
                        <br/><br/>
                        Tijdens het uitvoeren van een brandpreventie onderzoek zal de brandweer nagaan of het aantal toegelaten personen op de juiste manier bepaald is. Het maximaal aantal toegelaten personen kan op die moment door de brandweer verminderd worden op basis van de risico’s, de bezetting, de functie, de bereikbaarheid of de inplanting van de publiek toegankelijke inrichting.
                        <br/><br/>
                        <b>Opmerking:</b>
                    </p>
                    <ul>
                        <li>U als uitbater bent er altijd voor verantwoordelijk dat het aantal personen in uw zaak het toegelaten aantal niet overschrijdt.</li>
                        <li>Een bord met hierop het maximaal aantal toegelaten personen dat in de inrichting aanwezig mag zijn, moet door de uitbater, duidelijk leesbaar en goed zichtbaar, worden aangebracht bij de ingang(en).</li>
                    </ul>"
                    />
                    <Accordion
                        title="Ik wil een pand huren/kopen om er mijn zaak in te vestigen. Hoe weet ik of dit pand geschikt is?"
                        content="<p>
                        Alvorens over te gaan tot huren of kopen van een pand kan u best een  <a target='blank' href='https://www.brandweerzonerand.be/aanvraag-voorbespreking'>brandpreventieadvies</a> aanvragen. Dit kan bij brandweer Zone Rand maar eveneens bij een bevoegd persoon / firma.
                        <br/><br/>
                        Niet alle gebouwen zijn geschikt om een publiek toegankelijke inrichting in te vestigen. Vooral in oude gebouwen zijn (grote) investeringen nodig om te voldoen aan de voorwaarden van het reglement. <br/>
                        U kan een brandpreventieadvies aanvragen bij brandweer Zone Rand via onze
                        <a target='blank' href='https://www.brandweerzonerand.be/'>
                        website
                </a>. Dit vrijblijvende advies laat u toe een inschatting te maken van de nodige aanpassingen. <br/>
                        Hou er rekening mee dat dit advies niet gratis is, maar valt onder het retributiereglement.
                    </p>"
                    />
                    <Accordion
                        title="Ik wil mijn pand verbouwen. Hoe weet ik of ik aan alle voorwaarden voldoe?"
                        content="<p>
                        Bij een verbouwing adviseren wij u vooraf uw project met de brandweer te bespreken. <br/>
                        De brandweer zal u advies geven over de zaken waarmee u rekening moet houden. <br/>
                        Op die manier voorkomt u dat er na uw verbouwing nieuwe aanpassingen moeten gebeuren. <br/>
                        Alvorens over te gaan tot huren of kopen van een pand kan u best een  <a target='blank' href='https://www.brandweerzonerand.be/aanvraag-voorbespreking'>brandpreventieadvies</a> aanvragen. Dit kan bij brandweer Zone Rand maar eveneens bij een bevoegd persoon / firma.
                        <br/><br/>
                        Om te vermijden dat er na verbouwingswerken toch nog aanpassingen moeten gebeuren kan u een vrijblijvend brandpreventieadvies aanvragen bij brandweer Zone Rand. <br/>
                        Dit vrijblijvende advies laat u toe een inschatting te maken van de nodige aanpassingen. <br/>
                        De aanvraag kan u indienen via onze <a target='blank' href='https://www.brandweerzonerand.be'>website</a>. Hou er rekening mee dat dit advies niet gratis is maar valt onder het retributiereglement.
                    </p>"
                    />
                    <Accordion
                        title="Hoeveel uitgangen moet ik voorzien?"
                        content="<p>
                        Elke inrichting moet over minimum 1 uitgang beschikken met breedte 70 cm en vrije hoogte 2 m.
                        <br/><br/>
                        Het vereiste aantal uitgangen wordt verhoogd, rekening houdend met het aantal toegelaten personen, de categorie, de breedte van de aanwezig uitgangen, de ligging, de loopafstanden binnen de zaak, …
                        Het aantal uitgangen wordt bepaald per bouwlaag en de evacuatie voorschriften moeten gegarandeerd zijn tot op de openbare weg of veilige plaats.
                        <br/><br/>
                        Alle van toepassing zijnde voorwaarden kan je terugvinden in het reglement.
                        <br/><br/>
                        De basisregel is eenvoudig, het minimum aantal uitgangen wordt bepaald in functie van het aantal toegelaten personen:
                        1 uitgang: maximum 99 personen
                        2 uitgangen: maximum 499 personen
                        meer dan 2 uitgangen: het maximum aantal toegelaten personen =
                        (het aantal uitgangen x 1000) - 2000
                        <br/><br/>
                        Bepaalde omstandigheden kunnen ervoor zorgen dat dit aantal uitgangen toch moet verhoogd worden. Als bijvoorbeeld de af te leggen afstanden te groot zijn, kan het zijn dat er toch een extra uitgang moet voorzien worden.
                        <br/><br/>
                        De uitgangen dienen ook oordeelkundig verspreid te worden, anders worden ze niet meegerekend.
                        <br/><br/>
                        <img src='images/faq.png' width='300'>
                        Oordeelkundig verspreid = in tegenovergestelde zones. Voor het bepalen van tegenovergestelde zones voor elk willekeurig punt binnen het compartiment wordt het punt verbonden met alle uitgangen. Als de hoek tussen de verbindingslijnen met de uitgangen ≥45° is, dan liggen deze uitgangen in tegenovergestelde zones. Voor elk willekeurig punt in het compartiment moet voldaan worden aan deze voorwaarden. Het willekeurig punt mag op een maximale gemeenschappelijke afstand van +/-20 m liggen van de muren waarin geen uitgang aanwezig is.
                        <br/><br/>
                        In functie van de configuratie van de lokalen kan de brandweer het minimum aantal uitgangen verhogen.
                        <br/><br/>
                        Draaideuren worden hierbij NIET meegeteld.
                    </p>"
                    />
                    <Accordion
                        title="Hoe bepaal ik de totale breedte van mijn uitgangen en evacuatiewegen?"
                        content="<p>
                        De minimum breedte van een evacuatieweg bedraagt steeds 70 cm. <br/>
                        De totale breedte van de evacuatiewegen en uitgangen moet groter of gelijk zijn aan het aantal toegelaten personen uitgedrukt in cm (1 persoon/cm). <br/>
                        Voor trappen moet hier een reductiefactor worden toegepast:
                    </p>
                    <ul>
                        <li>aantal personen x 1,25 voor dalende trappen</li>
                        <li>aantal personen x 2 voor stijgende trappen</li>
                    </ul>"
                    />
                    <Accordion
                        title="Mag mijn (nood)uitgang op slot zijn?"
                        content="<p>
                        Aanwezigen moeten ten alle tijde op een eenvoudige manier kunnen evacueren. Met maximaal één handeling moet elke uitgang of nooduitgang kunnen geopend worden.
                        <br/><br/>
                        U kan maatregelen nemen om ongewenste toegang tot uw zaak te voorkomen op voorwaarde dat aan bovenstaande voorwaarde is voldaan. <br/>
                        De brandweer adviseert dan ook alle deuren van (nood)uitgangen uit te rusten met een anti-paniekbeslag. <br/>
                        Sleutelkastjes, codeklavieren, sleutels op de deur … zijn NIET toegelaten.
                    </p>"
                    />
                    <Accordion
                        title="Wat met een draaideur?"
                        content="<p>
                        U mag draaideuren gebruiken als toegangsdeur tot uw inrichting.
                        <br/>
                        Deze draaideuren zijn echter NIET toegelaten als uitgang of als nooduitgang bij een evacuatie. <br/>
                        Ze mogen dan ook niet in rekening gebracht worden bij het bepalen van het aantal uitgangen of bij het bepalen van de lengte van vluchtwegen.
                    </p>"
                    />
                    <Accordion
                        title="Hoeveel brandblussers hang ik in mijn zaak? Welke type adviseert de brandweer?"
                        content="<p>
                        Minimaal moet er 1 toestel per 150 m² vloeroppervlakte én 1 toestel per verdieping aanwezig zijn.
                        <br/><br/>
                        De te plaatsen brandblussers moeten minimaal 1 bluseenheid hebben. Dit zijn de toestellen van 6 kg ABC-poeder of 6 l water/schuim. <br/>
                        Als brandweer adviseren wij water/schuimblussers te plaatsen. De toestellen zijn makkelijk in gebruik én zorgen voor weinig (neven)schade. Hang deze toestellen wel op op een vorstvrije plaats.
                        <br/><br/>
                        Brandblustoestellen worden opgehangen op een hoogte van ca. 1 m, zijn vlot bereikbaar en voorzien van een pictogram.
                        <br/><br/>
                        Ter beveiliging van bijvoorbeeld uw elektriciteitskasten kan u gebruik maken van CO₂ blussers. Deze worden altijd in overtal geplaatst en tellen niet mee bij het bepalen van het aantal brandblussers.
                    </p>"
                    />
                    <Accordion
                        title="Welke periodieke controles moet ik wanneer uitvoeren?"
                        content="<p>Volgende installaties moeten periodiek gecontroleerd worden (indien van toepassing)</p>
                    <table>
                        <tr>
                            <th>Installatie</th>
                            <th>Wie</th>
                            <th>Periodiciteit</th>
                            <th>Categorie</th>
                        </tr>
                        <tr>
                            <td>Elektrische laagspanningsinstallaties</td>
                            <td>EO</td>
                            <td>5-jaarlijks</td>
                            <td>1, 2, 3</td>
                        </tr>
                        <tr>
                            <td>Elektrische hoogspanningsinstallaties</td>
                            <td>EO</td>
                            <td>jaarlijks</td>
                            <td>1, 2, 3</td>
                        </tr>
                        <tr>
                            <td>Veiligheidsverlichting – goede werking</td>
                            <td>BP</td>
                            <td>6 maandelijks</td>
                            <td>2, 3</td>
                        </tr>
                        <tr>
                            <td>Liftinstallatie (Personenlift)</td>
                            <td>EDTC</td>
                            <td>3-maandelijks (niet gecertificeerd)
                            6-maandelijks
                            (gecertificeerde firma volgens ISO 9001)</td>
                            <td>2, 3</td>
                        </tr>
                        <tr>
                            <td>Verwarmingsinstallatie met vaste en vloeibare brandstof
                            (verbrandings- en reinigingsattest)</td>
                            <td>BT</td>
                            <td>jaarlijks</td>
                            <td>1, 2, 3</td>
                        </tr>
                        <tr>
                            <td>Verwarmingsinstallatie met gasvormige brandstof
                            (verbrandings- en reinigingsattest)</td>
                            <td>BT</td>
                            <td>2-jaarlijks</td>
                            <td>1, 2, 3</td>
                        </tr>
                        <tr>
                            <td>Schoorsteen en rookkanalen (toestellen op vloeibare en vaste brandstoffen)</td>
                            <td>BT</td>
                            <td>jaarlijks</td>
                            <td>1, 2, 3</td>
                        </tr>
                        <tr>
                            <td>Alarminstallatie (geen norm) – goede werking</td>
                            <td>BT</td>
                            <td>jaarlijks</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>Algemene branddetectie-installatie –
                            conformiteit
                            norm</td>
                            <td>BP</td>
                            <td>bij ingebruikname + wijziging installatie</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>Algemene branddetectie-installatie –
                            goede werking
                            (inclusief sturingen zoals zelfsluitende deuren, …)</td>
                            <td>GKI</td>
                            <td>jaarlijks</td>
                            <td>2, 3</td>
                        </tr>
                        <tr>
                            <td>Brandbestrijdingsmiddelen
                            (brandblussers/muurhaspels/
                            automatische blusinstallatie)</td>
                            <td>BT</td>
                            <td>jaarlijks</td>
                            <td>1, 2, 3</td>
                        </tr>
                        <tr>
                            <td>Gasinstallatie
                            (de gasmeter(s), de binnenleidingen en de
                            gebruikstoestellen dichtheidsproef)</td>
                            <td>BT</td>
                            <td>6-maandelijks</td>
                            <td>1, 2, 3</td>
                        </tr>
                        <tr>
                            <td>Toestand brandwerende deuren</td>
                            <td>BP</td>
                            <td>jaarlijks</td>
                            <td>2, 3</td>
                        </tr>
                        <tr>
                            <td>Dampkappen</td>
                            <td>BP</td>
                            <td>jaarlijks</td>
                            <td>1, 2, 3</td>
                        </tr>
                    </table>
                    <p>Voor de toepassing van deze tabel wordt verstaan onder:</p>
                    <ul>
                        <li>GKI: geaccrediteerd keuringsinstantie waaronder
                            <ul style='padding-top: 20px'>
                                <li>EO: erkend organisme (elektrische installaties);</li>
                                <li>EDTC: externe dienst voor technische controle (liften);</li>
                            </ul>
                        </li>
                        <li>BT: bevoegde technicus;</li>
                        <li>BP: bevoegd persoon;</li>
                    </ul>
                    <br/>
                    <p>Bijkomende opmerkingen</p>
                    <ul>
                        <li>Elektrische laagspanningsinstallaties: bij de 5-jaarlijkse controle dient ook de veiligheidsverlichting door een erkend organisme nagezien te worden;</li>
                        <li>Elektrische hoogspanningsinstallaties: hierbij hoort ook de neonverlichting;</li>
                        <li>Veiligheidsverlichting: de 6-maandelijkse controle van de ‘goede werking’ dient aanwezig te zijn in het veiligheidsregister;</li>
                        <li>Het is aanbevolen om autonome rookmelders ook periodiek te controleren.</li>
                        <li>Verwarmingsinstallatie: dit geldt zowel voor de individuele als voor de centrale verwarmingsinstallaties en omvat het nazicht van stooktoestellen voor de verwarming van gebouwen alsook voor de aanmaak van warm water. Hieronder vallen ook de aerothermen.</li>
                        <li>Algemene branddetectie-installatie – conformiteit met norm (NBN S21-100-1 en NBN S21-100-2) is enkel van toepassing bij nieuwe inrichting waar het aantal personen groter is dan 500;</li>
                        <li>Brandbestrijdingsmiddelen: de brandblussers dienen het BENOR- label te hebben.</li>

                    </ul>"
                    />
                </div>
            </div>
        </div>
    );
};

export default FaqPage;
