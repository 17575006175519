import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Header from "../components/Header";
import Button from "../components/Button";
import CategoryForm from "../components/CategoryForm";
import Loading from "../components/Loading";
import Footer from "../components/Footer";
import { createSession, updateSession } from "../services/sessionService";
import { createSessionProperty } from "../services/sessionPropertyService";
import TagManager from "react-gtm-module";

const PropertyPage = (props) => {
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [session, setSession] = useState({
        exploitantSince: "",
        activity: "",
        size: "",
        capacity: "",
        categoryNr: "",
        company: "",
        zipCode: "",
        email: "",
        phone: "",
        sessionStartDate: new Date().toISOString(),
        sessionStatus: "active",
        sessionGlobalScore: "",
    });
    const [isLoading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [errors, setErrors] = useState({});
    let [isOther, setIsOthers] = useState(false);
    let [sessionId, setSessionId] = useState(0);

    TagManager.dataLayer({
        dataLayer: {
            event: 'pageview',
            pagePath: 'mijn-pand',
            pageTitle: 'Mijn pand',
        },
    });

    useEffect(() => {
        if (props.location.state) {
            setSession(props.location.state.session);
            setSessionId(props.location.state.sessionId);
            setProperties(props.location.state.properties);
            setLoading(false);
        } else {
            if (!sessionId) {
                createSession(session).then((resp) => {
                    setLoading(false);

                    if (resp.data) {
                        setSessionId(resp.data.id);
                    }
                });
            }
        }
    }, [props, session, sessionId]);

    function nextStep() {
        let formIsValid = true;
        let errors = {};

        switch (step) {
            case 1:
                if (!session.exploitantSince) {
                    formIsValid = false;
                    errors["exploitantSince"] = "Dit veld is verplicht.";
                }
                if (!session.activity) {
                    formIsValid = false;
                    errors["activity"] = "Dit veld is verplicht.";
                }
                if (!session.categoryNr) {
                    formIsValid = false;
                    errors["categoryNr"] = "Dit veld is verplicht.";
                }
                break;
            case 2:
                setProperties([]);
                break;
            default:
                break;
        }

        if (formIsValid) {
            switch (step) {
                case 1:
                    patchSession();
                    break;
                case 2:
                    patchSessionProperties();
                    break;
                default:
                    break;
            }
            setStep((step) => step + 1);
        }
        setErrors(errors);
    }

    function previousStep() {
        patchSession();

        if (step === 1) return;

        setStep((step) => step - 1);
    }

    const updateData = (newData) => {
        switch (step) {
            case 1:
                if (newData.target.id === "andere") {
                    setSession({
                        ...session,
                        [newData.target.name]: null,
                    });
                    isOther = true;
                    setIsOthers(true);
                } else {
                    setSession({
                        ...session,
                        [newData.target.name]: newData.target.value,
                    });
                }
                break;
            case 2:
                let prop_copy = Array.from(properties);
                if (newData.target.checked) {
                    prop_copy.push({
                        id: newData.target.name,
                        value: newData.target.checked,
                    });
                } else {
                    var pos = prop_copy.findIndex(function (e) {
                        return e.id === newData.target.name;
                    });
                    prop_copy.splice(pos, 1);
                }
                setProperties(prop_copy);
                break;
            case 3:
                setSession({
                    ...session,
                    [newData.target.name]: newData.target.value,
                });
                break;
            default:
                break;
        }
    };

    const patchSession = () => {
        updateSession(sessionId, session);
    };

    const patchSessionProperties = () => {
        let propIds = properties.map((x) => parseInt(x.id));

        let data = {
            session: sessionId,
            property: propIds.join(","),
        };

        createSessionProperty(data).then((resp) => {});

        history.push("/thema", {
            session,
            sessionId,
            properties,
        });
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="page">
            <Header
                pageTitle="Mijn"
                pageSubtitle="pand"
                currentStep={step}
                steps="2"
            />

            <div className="page__content">
                <div className="container">
                    <CategoryForm
                        step={step}
                        session={session}
                        sessionProperties={properties}
                        errors={errors}
                        updateData={updateData}
                        isOther={isOther}
                    />

                    <Button
                        type="button"
                        theme="primary"
                        classes="margin-top-lg margin-bottom-sm"
                        onClick={nextStep}
                    >
                        Volgende stap
                    </Button>
                    <Button
                        type="button"
                        theme="transparent"
                        onClick={previousStep}
                    >
                        Vorige stap
                    </Button>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default PropertyPage;
