const ProgressBar = ({ current, max }) => {
    const percentage = current && max ? (current / max) * 100 : 100;

    return (
        <div className="progressbar">
            <span className="progressbar__progress" style={{ width: `${percentage}%` }}></span>
        </div>
    )
}

export default ProgressBar
