import Header from "../components/Header";

const DoelPage = () => {
    return (
        <div className="page">
            <Header pageTitle="Doel" backButton="back" />

            <div className="page__content">
                <div className="container">
                    <p>
                        Vanaf 1 september 2021 is er binnen de gemeenten van
                        brandweer Zone Rand een nieuw brandveiligheidsreglement
                        voor <b>publiek toegankelijke inrichtingen</b>, zoals
                        horeca, winkels, jeugdhuizen, feestzalen ... van kracht.
                    </p>
                    <h2>Één brandweerzone, één reglement</h2>
                    <p>
                        “Brandweer Zone Rand telt 21 gemeenten die de Belgische
                        regels elk op hun eigen manier hadden uitgewerkt”. Dat
                        was verwarrend voor eigenaars en uitbaters. <br />
                        De brandweer moest op basis van verschillende regels een
                        ander advies geven. <br />
                        Met één reglement willen we een gelijke bescherming voor
                        alle inwoners van Brandweer Zone Rand én duidelijk maken
                        wat je als uitbater moet doen om een brandveilige zaak
                        uit te baten.
                    </p>
                    <h2>Doe de zelftest</h2>
                    <p>
                        Op deze website kan je anoniem een brandveiligheidstest
                        doen. Zo kan je zelf checken of je zaak voldoet aan de
                        voorwaarden van het reglement en je -indien nodig- in
                        regel stellen. <br />
                        Je ontvangt een duidelijk overzicht van de nog uit te
                        voeren aanpassingen. Indien gewenst kan je na de test
                        een brandweeradvies op maat aanvragen.
                        <br />
                        <br />
                        De test is anoniem en vrijblijvend.
                    </p>
                    <h2>Geen haast, wél veilig</h2>
                    <p>
                        Is je zaak (nog) niet in orde? <br />
                        Geen paniek, bestaande zaken hebben 10 jaar tijd om zich
                        in regel te stellen.
                        <br />
                        <br />
                        Plan je in tussentijd een verbouwing, een herinrichting,
                        een herbestemming of een andere aanpassing? Of koop je
                        een zaak over? <br />
                        Dan heb je een half jaar tijd om een stappenplan uit te
                        werken waarmee je binnen de 5 jaar je zaak in orde
                        brengt. <br />
                        Zo geven we uitbaters voldoende tijd en financiële
                        ruimte, zonder in te boeten aan brandveiligheid.
                    </p>
                    <h2>Onze gouden tip?</h2>
                    <p>
                        Vraag advies aan Brandweer Zone Rand vóór je een zaak
                        aanpast, huurt of koopt.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default DoelPage;
