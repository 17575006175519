export async function createSessionAnswers(data) {
    const response = await fetch(process.env.REACT_APP_API_BASE_URL + "sessionanswers", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    if (response.ok) return response.json();

    throw response;
}
