import { useState } from "react"
import { Link } from "react-router-dom";

import PageTitle from "../components/PageTitle";
import Button from "../components/Button";
import Footer from "../components/Footer"
import Modal from "../components/Modal";

const Home = () => {
    const [openModal, setOpenModal] = useState(false);

    const toggleModal = () => {
        setOpenModal(!openModal);
    }

    return (
        <>
            <div className="page page--home">
                <div className="container">
                    <div className="site-logo">
                        <img src="../images/logo.svg" alt="Brandweer Zone Rand logo" className="site-logo__img" />
                    </div>

                    <PageTitle
                        title="Je zaak checken op brandveiligheid duurt minder lang"
                        subTitle="dan deze zin achterstevoren te lezen."
                        pageType="basic"
                        classes="margin-bottom-lg"
                    />

                    <h5 className="margin-bottom-sm">Het checken is slechts 2 minuten werk</h5>

                    <Link to={{ pathname: "mijn-pand" }} className="btn margin-top-lg margin-bottom-sm">Check jouw pand</Link>
                    <Button type="button" theme="outlined" classes="margin-bottom" onClick={toggleModal}>Extra uitleg</Button>
                </div>
            </div>

            <Footer />

            <Modal handler={toggleModal} modalState={openModal} ctaLabel="Check mijn pand" ctaUrl="mijn-pand">
                <h2>Meer informatie over de checklist brandveiligheid</h2>
                <p>Klik op een van onderstaande knoppen voor meer informatie</p>
                <Link to="faq" theme="lightgreen" type="button" className="btnLightgreen margin-bottom" >Veelgestelde vragen</Link>
                <Link to="terminologie" theme="lightgreen" type="button" className="btnLightgreen margin-bottom" >Terminologie</Link>
                <Link to="doel" theme="lightgreen" type="button" className="btnLightgreen margin-bottom" >Doel van de tool</Link>
                <Link to="contact" theme="lightgreen" type="button" className="btnLightgreen margin-bottom-lg" >Contact</Link>
            </Modal>
        </>
    )
}

export default Home
