import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import Header from "../components/Header";
import Result from "../components/Result";
import Loading from "../components/Loading";
import { getResult } from "../services/resultService";
import TagManager from "react-gtm-module";

const ResultPage = (props) => {
    const [scores, setScores] = useState([]);
    const [isLoading, setLoading] = useState(true);
    var sessionId = props.location.state.state.sessionId.toString();

    TagManager.dataLayer({
        dataLayer: {
            event: 'pageview',
            pagePath: 'resultaat',
            pageTitle: 'resultaat',
        },
    });

    useEffect(() => {
        getResult(sessionId)
            .then((resp) => {
                setScores(resp.data);
                setLoading(false);
            })
            .catch((e) => setLoading(false));
    }, [sessionId]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="page">
            <Header pageTitle="Uw" pageSubtitle="resultaat" />

            <div className="page__content">
                <div className="container">
                    <h2>Resultaten en actiepunten</h2>
                    <p>
                        Hieronder vind je de resultaten van de checklist.
                        Om de brandveiligheid in ja zaak nog te  verbeteren vind je eveneens actiepunten terug.
                    </p>

                    <img
                        src="../images/label.svg"
                        alt="Logo label"
                        className="margin-top margin-bottom"
                        style={{ width: "113px" }}
                    />

                    <div className="margin-bottom-lg">
                        {scores.map((score, index) => (
                            <Result
                                key={index}
                                title={score.thema}
                                description={score.scoreText}
                                score={score.score}
                                todos={score.todo}
                                state={props.location.state.state}
                            />
                        ))}
                    </div>

                    <Link
                        to={{
                            pathname: "ontvang-documentatie",
                            state: { scores, sessionId },
                        }}
                        className="btn margin-bottom-sm"
                    >
                        Ontvang documentatie
                    </Link>
                    <a className="btn--transparent margin-bottom-lg" href="https://www.brandweerzonerand.be/aanvraag-controle-brandveiligheid" target="_blank">Maak een afspraak</a>

                </div>
            </div>
        </div>
    );
};

export default ResultPage;
