import { FaTimes } from 'react-icons/fa';

import { Link } from "react-router-dom";
import { ModalClose, ModalFooter, ModalMain, ModalWrapper, StyledModal } from './ModalElements';

const Modal = ({ handler, modalState, ctaLabel, ctaUrl, children }) => {
    return (
        <ModalWrapper show={ modalState }>
            <StyledModal>
                <ModalClose type="button" onClick={ handler }>
                    <FaTimes />
                </ModalClose>
                <ModalMain>
                    { children }
                </ModalMain>
                <ModalFooter>
                    <Link to="mijn-pand" theme="lightgreen" type="button" className="btn margin-bottom-sm" >Check mijn pand</Link>
                </ModalFooter>
            </StyledModal>
        </ModalWrapper>
    )
}

export default Modal