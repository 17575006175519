import styled from "styled-components";

export const LoadingWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
`;

export const LoadingImage = styled.img`
    width: 146px;
`;