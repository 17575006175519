import styled, { css } from 'styled-components';

export const TooltipWrapper = styled.div`
    position: relative;

    ${props =>
        props.open &&
        css`
            ${TooltipIcon} {
                background-color: #CD163F;
                color: white;
            }

            ${TooltipText} {
                display: block;
                height: 12rem;
                overflow: hidden
            }
        `}
`;

export const TooltipIcon = styled.button`
    position: absolute;
    top: -26px;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #CD163F;
    color: #CD163F;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const TooltipText = styled.span`
    position: absolute;
    background-color: white;
    color: black;
    border: 2px solid #CD163F;
    border-radius: 5px;
    padding: 10px;
    bottom: 34px;
    right: 0;
    display: none;
   
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        right: 5px;
        border-width: 10px;
        border-style: solid;
        border-color: #CD163F transparent transparent transparent;
    }
`;