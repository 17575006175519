import { useState, useEffect } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";

import Header from "../components/Header";
import ThemeForm from "../components/ThemeForm";
import Button from "../components/Button";
import Loading from "../components/Loading";
import { createTheme } from "../services/themeService";
import { createSessionAnswers } from "../services/sessionanswersService";

const ThemePage = () => {
    const [step, setStep] = useState(0);
    const [themes, setThemes] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [answers, setAnswers] = useState([]);
    var [selectedAnswerQuestion, setSelectedAnswerQuestion] = useState([]);
    const { state } = useLocation();
    const history = useHistory();
    const [propsIds, setPropsIds] = useState({ properties: {} });

    useEffect(() => {
        if (state.properties) {
            setPropsIds({
                properties: state.properties.map((x) => parseInt(x.id)),
            });
        }
    }, [state]);

    useEffect(() => {
        window.scrollTo(0, 0);

        createTheme(state.session.categoryNr, propsIds).then((resp) => {
            setThemes(resp.data);
            console.log(resp.data);
            setLoading(false);
        });
    }, [state, propsIds]);

    function updateAnswers(obj) {
        return createSessionAnswers(obj);
    }

    async function nextStep() {
        var arry = Object.values(selectedAnswerQuestion);
        if (arry && arry.length > 0) {
            var answersId = arry.map((x) => x.answerId);
            if (answersId === undefined) {
                answersId = [1];
            }
            var obj = {
                session: state.sessionId,
                question: parseInt(arry[0].questionId),
                answers: answersId.join(","),
            };
            selectedAnswerQuestion = {};
            setSelectedAnswerQuestion([]);
            await updateAnswers(obj);
            if (step === themes.length - 1) {
                let params = {
                    state: state,
                    answers: answers,
                };
                history.push("/uw-uitslag", params);
            }
            else {
                setStep((step) => step + 1);
            }
        }
        else {
            if (step === themes.length - 1) {
                let params = {
                    state: state,
                    answers: answers,
                };
                history.push("/uw-uitslag", params);
            }
            else {
                setStep((step) => step + 1);
            }
        }
    }

    function previousStep() {
        if (step === 0) return;
        setStep((step) => step - 1);
    }

    const updateData = (formData) => {
        var answerId;
        if (formData.target.checked) {
            answerId = formData.target.name;
        }
        var select = {
            answerId: answerId,
            questionId: formData.target.value,
            sessionId: state.id,
        };
        let selectedAnswer_copy = {
            ...selectedAnswerQuestion,
            [formData.target.name]: select,
        };
        setSelectedAnswerQuestion(selectedAnswer_copy);

        let answers_copy = {
            ...answers,
            [formData.target.name]: formData.target.checked,
        };
        setAnswers(answers_copy);
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="page">
            <Header
                pageTitle={themes[step].theme}
                pageSubtitle={themes[step].subTitle}
                currentStep={step + 1}
                steps={themes.length}
            />

            <div className="page__content">
                <div className="container">
                    <ThemeForm
                        step={step}
                        themes={themes[step]}
                        updateData={updateData}
                        answers={answers}
                        tooltip={themes[step].tooltip}
                    />
                    {step < themes.length && (
                        <Button
                            type="button"
                            theme="primary"
                            classes="margin-bottom-sm"
                            onClick={nextStep}
                        >
                            Volgende stap
                        </Button>
                    )}
                    {step > 0 && (
                        <Button
                            type="button"
                            theme="transparent"
                            onClick={previousStep}
                        >
                            Vorige stap
                        </Button>
                    )}
                    {step === 0 && (
                        <Link
                            to={{ pathname: "mijn-pand", state: state }}
                            className="btn--transparent"
                        >
                            Vorige stap
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ThemePage;
