import CategoryFormStep1 from "./CategoryFormStep1";
import CategoryFormStep2 from "./CategoryFormStep2";

const CategoryForm = ({
    step,
    session,
    sessionProperties,
    errors,
    updateData,
    isOther,
}) => {
    return (
        <form>
            {(() => {
                switch (step) {
                    case 1:
                        return (
                            <CategoryFormStep1
                                data={session}
                                errors={errors}
                                handleChange={updateData}
                                isOther={isOther}
                            />
                        );

                    case 2:
                        return (
                            <CategoryFormStep2
                                data={session}
                                sessionProperties={sessionProperties}
                                errors={errors}
                                handleChange={updateData}
                            />
                        );

                    default:
                        return;
                }
            })()}
        </form>
    );
};

export default CategoryForm;
