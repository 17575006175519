import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TagManager from "react-gtm-module";

import Home from "./pages";
import PropertyPage from "./pages/property";
import ThemePage from "./pages/theme";
import ThanksPage from "./pages/thanks";
import ResultPage from "./pages/result";
import AppointmentPage from "./pages/appointment";
import ToDoPage from "./pages/todos";
import FaqPage from "./pages/faq";
import TerminologiePage from "./pages/terminologie";
import DoelPage from "./pages/doel";
import ContactPage from "./pages/contact";
import MailPage from "./pages/mail";
import { useEffect } from "react";

function App() {
    useEffect(() => {
        TagManager.initialize({ gtmId: "GTM-WPJN98D" });
    }, []);

    return (
        <>
            <Router>
                <Switch>
                    <Route path="/" component={Home} exact />
                    <Route path="/bedankt" component={ThanksPage} exact />
                    <Route path="/mijn-pand" component={PropertyPage} exact />
                    <Route path="/thema" component={ThemePage} exact />
                    <Route path="/uw-uitslag" component={ResultPage} exact />
                    <Route
                        path="/afspraak-maken"
                        component={AppointmentPage}
                        exact
                    />
                    <Route
                        path="/ontvang-documentatie"
                        component={MailPage}
                        exact
                    />
                    <Route path="/mijn-todos" component={ToDoPage} exact />
                    <Route path="/faq" component={FaqPage} exact />
                    <Route
                        path="/terminologie"
                        component={TerminologiePage}
                        exact
                    />
                    <Route path="/doel" component={DoelPage} exact />
                    <Route path="/contact" component={ContactPage} exact />
                </Switch>
            </Router>
        </>
    );
}

export default App;
