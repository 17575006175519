import PageTitle from "../components/PageTitle";
import { Link } from "react-router-dom";

const ThanksPage = () => {
    return (
        <div className="page page--thanks">
            <div className="container">
                <div className="site-logo">
                    <img
                        src="../images/logo.svg"
                        alt="Brandweer Zone Rand logo"
                        className="site-logo__img"
                    />
                </div>

                <PageTitle
                    title="Jouw klanten bedanken je "
                    subTitle="voor het checken van jouw pand"
                    pageType="basic"
                    classes="margin-bottom"
                />

                <p>
                    Deze checklist was slechts een richting, een aanzet. Neem
                    gerust contact op met ons om de brandveiligheid in uw pand
                    verder in detail te bespreken. Dit is belangrijk voor jou en
                    jouw klanten.
                </p>

                <Link
                    type="button"
                    to={{ pathname: "contact" }}
                    className="btn"
                >
                    Contacteer ons
                </Link>
            </div>
        </div>
    );
};

export default ThanksPage;
