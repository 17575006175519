import { FaInfo } from 'react-icons/fa';
import { useState } from 'react';

import { TooltipWrapper, TooltipIcon, TooltipText } from './TooltipElements';

const Tooltip = ({ children }) => {
    const [state, setstate] = useState(false);


    const toggle = () => {
        setstate(!state);
    }

    return (
        <TooltipWrapper open={state}>
            <TooltipIcon type="button" onClick={toggle}>
                <FaInfo />
            </TooltipIcon>

            <TooltipText>
                { children }
            </TooltipText>
        </TooltipWrapper>
    )
}

export default Tooltip
