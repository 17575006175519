import Error from "../Error";
import Tooltip from "../Tooltip";

const CategoryFormStep1 = ({ data, errors, handleChange, isOther }) => {
    return (
        <div>
            <p>Om de juiste controlepunten in deze test te activeren, willen we vooraf een aantal dingen over je pand weten. Kruis aan wat van toepassing is.</p>

            <div className="formInput formInput--radio margin-bottom-lg">
                <Tooltip>
                    In het reglement wordt onderscheid gemaakt tussen nieuwe en bestaande inrichtingen.
                    Een nieuwe inrichting is een inrichting die na het in voege gaan van <a href="https://www.brandweerzonerand.be/ikcheckmijnzaak#inwerking" target="_blank">dit reglement</a>*
                    <ul className={"list"}>
                        <li>nieuw werd opgericht</li>
                        <li>wijzigt in hoofdfunctie</li>
                        <li>wijzigt van exploitant</li>
                        <li>wijzigt van organen van de rechtspersonen </li>
                    </ul>
                    <p>
                        * In de gemeenten Brasschaat, Brecht, Kontich en Zoersel is het reglement later van kracht gegaan, de exacte datum vind je <a href="https://www.brandweerzonerand.be/ikcheckmijnzaak#inwerking" target="_blank">hier</a>
                    </p>
                </Tooltip>

                <p>Ik ben</p>

                <label htmlFor="pre">Exploitant van deze zaak voor 01/09/2021
                    <input type="radio" name="exploitantSince" id="pre" value="pre" onChange={handleChange} checked={data.exploitantSince === "pre"} />
                    <span className="checkmark"></span>
                </label>

                <label htmlFor="post">Exploitant van deze zaak na 01/09/2021
                    <input type="radio" name="exploitantSince" id="post" value="post" onChange={handleChange} checked={data.exploitantSince === "post"} />
                    <span className="checkmark"></span>
                </label>

                {errors && <Error>{errors.exploitantSince}</Error>}
            </div>

            <div className="formInput formInput--radio margin-bottom-lg">
                <Tooltip>
                    De categorie wordt bepaald door het <a href="https://ikcheckmijnzaak.be/faq#personen" target="_blank">aantal toegelaten personen</a>. De exploitant bepaalt dit aantal binnen de voorwaarden opgenomen in het reglement.
                </Tooltip>
                <p>Activiteit</p>

                <label htmlFor="handelszaak">Handelszaak
                    <input type="radio" name="activity" id="handelszaak" value="handelszaak" onChange={handleChange} checked={data.activity === "handelszaak"} />
                    <span className="checkmark"></span>
                </label>

                <label htmlFor="horeca">Horeca
                    <input type="radio" name="activity" id="horeca" value="horeca" onChange={handleChange} checked={data.activity === "horeca"} />
                    <span className="checkmark"></span>
                </label>


                <label htmlFor="andere">Andere
                    <input type="radio" name="activity" id="andere" value="andere" onChange={handleChange} />
                    <span className="checkmark"></span>
                </label>


                {errors && <Error>{errors.activity}</Error>}
            </div>


            {isOther ? (
                <div className="formInput formInput--text margin-bottom-xlg-s">
                    <input type="text" name="activity" placeholder="Geef hier je activiteit in" id="activity" onChange={handleChange} />
                </div>
            ) : (
                <span></span>
            )}
             
            <div className="formInput formInput--radio">
                <Tooltip>
                    De categorie wordt bepaald door het aantal toegelaten personen* <br/>
                    Categorie 1: maximaal 9 personen toegelaten <br/>
                    Categorie 2: 9 &lt; personen toegelaten &lt; 49 personen <br/>
                    Categorie 3: vanaf 50 toegelaten personen <br/>
                    * het bepalen van het aantal toegelaten personen is gebonden aan een regels beschreven in het politiereglement
                </Tooltip>

                <p>Voor welke categorie wilt u een aanvraag doen?</p>

                <label htmlFor="category_1">Categorie 1 (aantal toegelaten personen is kleiner dan 10)
                    <input type="radio" name="categoryNr" id="category_1" value="1" onChange={handleChange} checked={data.categoryNr === "1"} />
                    <span className="checkmark"></span>
                </label>

                <label htmlFor="category_2">Categorie 2 (aantal toegelaten personen is groter of gelijk aan 10 maar minder dan 50)
                    <input type="radio" name="categoryNr" id="category_2" value="2" onChange={handleChange} checked={data.categoryNr === "2"} />
                    <span className="checkmark"></span>
                </label>

                <label htmlFor="category_3">Categorie 3 (aantal toegelaten personen is 50 of meer)
                    <input type="radio" name="categoryNr" id="category_3" value="3" onChange={handleChange} checked={data.categoryNr === "3"} />
                    <span className="checkmark"></span>
                </label>

                {errors && <Error>{errors.categoryNr}</Error>}
            </div>

        </div>
    )
}

export default CategoryFormStep1
