import { ScoreBarWrapper, ScoreBarItem } from "./ScoreBarElements";

const ScoreBar = ({ score }) => {
    let bgClass = '#D5EBD7';

    if (score < 40) {
        bgClass = '#e0bcc4';
    }
    if (score >= 40 && score < 60 ) {
        bgClass = '#e9cdb8';
    }

    return (
        <ScoreBarWrapper >
            <ScoreBarItem bg={bgClass} success={ score >= 60 } warning={ score > 20 && score < 59 } error={ score < 39 && score > 1 } />
            <ScoreBarItem bg={bgClass} success={ score >= 60}  warning={ score < 59 && score > 40 } />
            <ScoreBarItem bg={bgClass} success={ score >= 60 } />
            <ScoreBarItem bg={bgClass} success={ score >= 80 } />
            <ScoreBarItem bg={bgClass} success={ score > 99}  />
        </ScoreBarWrapper>
    )
}

export default ScoreBar
