import styled, { css } from 'styled-components';

export const ScoreBarWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const ScoreBarItem = styled.span`
    display: block;
    height: 5px;
    width: 100%;
    border-radius: 3.5px;
    margin-right: 2.5px;
    background-color: ${props => props.bg};
    &:last-child {
        margin-right: 0;
    }
    
    ${props =>
        props.success &&
        css`
            background-color: #40AC49;
        `}

    ${props =>
        props.warning &&
        css`
            background-color: #F9862C;
        `}

    ${props =>
        props.error &&
        css`
            background-color: #CD163F;
        `}
`;