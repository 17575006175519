const Flame = ({ currentStep, steps }) => {
    let inner;

    if (currentStep && steps) {
        inner = <h3>{ currentStep }/{ steps }</h3>
    }
    
    return (
        <div className="flame">
            { inner }
        </div>
    )
}

export default Flame
