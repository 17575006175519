import Header from "../components/Header";

const TerminologiePage = () => {
    return (
        <div className="page">
            <Header pageTitle="Terminologie" backButton="back" />

            <div className="page__content">
                <div className="container">
                    <h2>Publiek toegankelijke inrichting (PTI):</h2>
                    <p>
                        Een inrichting toegankelijk voor het publiek inclusief de
                        aanhorigheden
                        <br />
                        <br />
                        <i>Toelichting</i>
                        <br />
                        Dit is onafhankelijk van de bestaansduur van de
                        inrichting. Ook een pop-up wordt beschouwd als een PTI
                    </p>
                    <h2>Exploitant:</h2>
                    <p>
                        Natuurlijke persoon of rechtspersoon die, al dan niet
                        tijdelijk, een inrichting of installatie exploiteert,
                        haar, al dan niet tijdelijk, in bezit heeft of er, al
                        dan niet tijdelijk, economische zeggenschap over heeft.
                        <br />
                        <br />
                        <i>Toelichting</i>
                        <br />
                        Indien de eigenaar of exploitant de inrichting ter
                        beschikking stelt aan derden, onder welke vorm ook, is
                        deze verplicht de geldende brandveiligheidsvoorschriften
                        schriftelijk ter kennis te brengen aan deze derden en
                        dit tegen ontvangstbewijs. Bij voorkeur worden de
                        brandveiligheidsvoorschriften opgenomen in het
                        huurcontract.
                    </p>
                    <h2>Aanhorigheden:</h2>
                    <p>
                        Alle ruimten die in functie staan van het publiek
                        toegankelijke gedeelte. Hiertoe behoren o.a.
                        Kitchenettes en keukens, bergingen, sanitair, ...
                        <br />
                        <br />
                        Het privé woongedeelte van de exploitant maakt eveneens
                        deel uit van de aanhorigheden, tenzij brandwerend
                        gecompartimenteerd met wanden EI60, en deuren EI₁30.
                        <br />
                        <br />
                        <i>Toelichting</i>
                        <br />
                        Indien de verwarmingsinstallatie van het PTI in het
                        privé-gedeelte (bv. appartement van de exploitant,
                        gemeenschappelijke kelder van appartementsgebouw, …)
                        staat, maakt deze ruimte geen deel uit van de controle.
                    </p>
                    <h2>Bestaande inrichting:</h2>
                    <p>
                        Een inrichting die reeds voor de invoering van het
                        reglement in exploitatie was en intussen niets is
                        gewijzigd. <br />
                        <a href="https://www.brandweerzonerand.be/ikcheckmijnzaak#inwerking">
                            In welke gemeente is dit reglement van toepassing
                        </a>
                    </p>
                    <h2>Nieuwe inrichting:</h2>
                    <p>
                        Is een inrichting die na de inwerkingtreding van dit
                        reglement:
                    </p>
                    <ul>
                        <li>nieuw werd opgericht;</li>
                        <li>waarvan de hoofdfunctie wijzigt;</li>
                        <li>waarvan de exploitant wijzigt;</li>
                        <li>
                            waarvan de organen van de rechtspersoon wijzigen;
                        </li>
                    </ul>
                    <p>
                        <a href="https://www.brandweerzonerand.be/ikcheckmijnzaak#inwerking">
                            In welke gemeente is dit reglement van toepassing
                        </a>
                    </p>
                    <h2>Verbouwen:</h2>
                    <p>
                        Er worden structurele wijzigingen uitgevoerd,
                        bijvoorbeeld een dragende muur die verwijderd wordt.
                        <br />
                        <br />
                        Verfraaiingswerken worden niet beschouwd als een
                        verbouwing.
                    </p>
                    <h2>Uitbreiden:</h2>
                    <p>
                        Een publiek toegankelijke inrichting in oppervlakte
                        en/of volume vergroten.
                        <br />
                        <br />
                        Enkele voorbeelden:
                    </p>
                    <ul>
                        <li>aanbouw van een veranda of een bijkeuken</li>
                        <li>plaatsen van een tussenvloer</li>
                    </ul>
                    <h2>Herinrichten:</h2>
                    <p>
                        Van een bestaande PTI de inrichting wijzigen zonder
                        aanpassing aan de structuur. <br />
                        Verfraaiingswerken zoals een nieuwe vloer of
                        wandbekleding of de herindeling van bestaande ruimtes
                        vallen binnen het herinrichten.
                    </p>
                    <h2>Indeling in categorieën:</h2>
                    <p>
                        De publiek toegankelijke inrichtingen zijn ingedeeld in
                        3 categorieën, volgens het aantal toegelaten personen:
                    </p>
                    <ul>
                        <li>Categorie 1: maximum 9 personen toegelaten;</li>
                        <li>
                            Categorie 2: minimum 10 en maximum 49 personen
                            toegelaten;
                        </li>
                        <li>Categorie 3: 50 personen of meer toegelaten.</li>
                    </ul>
                    <h2>Weerstand tegen brand:</h2>
                    <p>
                        Voor bouwelementen met een dragende en/of scheidende
                        functie wordt de weerstand tegen brand uitgedrukt zoals
                        gedefinieerd in de Europese norm NBN EN 13501 (2 tot 4).
                        Klasseringen die worden bekomen volgens de Belgische
                        norm NBN 713.020, worden als volgt als evenwaardig
                        aanvaard:
                    </p>
                    <table>
                        <tr>
                            <th>NBN EN 13501 (2 tot 4)</th>
                            <th>NBN 713.020</th>
                        </tr>
                        <tr>
                            <td>Voor R 30, RE 30, REI 30 en EI 30</td>
                            <td>Voldoet Rf ½ u</td>
                        </tr>
                        <tr>
                            <td>Voor R 60, RE 60, REI 60 en EI 60</td>
                            <td>Voldoet Rf 1 u</td>
                        </tr>
                        <tr>
                            <td>Voor R 120, RE 120, REI 120 en EI 120</td>
                            <td>Voldoet Rf 2 u</td>
                        </tr>
                        <tr>
                            <td>Voor EI1 30</td>
                            <td>Voldoet Rf ½ u</td>
                        </tr>
                        <tr>
                            <td>Voor EI1 60</td>
                            <td>Voldoet Rf 1 u</td>
                        </tr>
                    </table>
                    <p>Toelichting:</p>
                    <ul>
                        <li>
                            Muren in metselwerk of beton met een dikte van
                            minimum 90mm worden aanvaard als wand met
                            brandweerstand EI 60.
                        </li>
                        <li>
                            Een deur met een scheidende functie van een half
                            uur: EI₁ 30 (vroeger Rf ½ h).
                        </li>
                        <li>
                            Een wand met een scheidende en dragende functie van
                            een uur: REI 60 (vroeger Rf 1 h).
                        </li>
                        <li>
                            Een staalstructuur met een stabiliteit van een uur:
                            R 60 (vroeger Rf 1 h).
                        </li>
                        <li>
                            Een wand met enkel een scheidende functie van twee
                            uur: EI 120 (vroeger Rf 2 h).
                        </li>
                    </ul>
                    <p>
                        <i>Opgelet:</i>
                        <br />
                        Doorvoeren doorheen wanden of vloeren mogen de weerstand
                        tegen brand van deze wanden of vloeren niet nadelig
                        beïnvloeden
                    </p>
                    <h2>Regels van goed vakmanschap:</h2>
                    <p>
                        De ‘regels van goed vakmanschap’ vormen het geheel van
                        de bepalingen, beschreven in normen, opgesteld of
                        geregistreerd door het Belgisch Bureau voor Normalisatie
                        (NBN) (of ook in toepasselijke Europese of buitenlandse
                        normen), in voorschriften van verzekeraars, in
                        vakbladen, etc.
                    </p>
                    <h2>Basisnormen:</h2>
                    <p>
                        Het Koninklijk besluit van 7 juli 1994 tot vaststelling
                        van de basisnormen voor de preventie van brand en
                        ontploffing waaraan nieuwe gebouwen moeten voldoen [en
                        latere wijzigingen].
                    </p>
                    <h2>Keuken:</h2>
                    <p>
                        Een lokaal, of een gedeelte daarvan, waarin zich
                        toestellen bevinden met een totaal gecummuleerd
                        elektrisch vermogen groter of gelijk aan 10 kW of met
                        open vlam.
                    </p>
                    <h2>Totale publiek toegankelijke oppervlakte:</h2>
                    <p>
                        Oppervlakte van het publiek toegankelijk gedeelte binnen
                        de buitenmuren zonder aftrek van toonbanken, tapkasten
                        en ander meubilair of goederen.
                        <br />
                        <br />
                        <i>Toelichting:</i>
                        <br />
                        Dit is de som van de vloeroppervlakten van alle voor het
                        publiek toegankelijke binnenruimtes van de inrichting,
                        inclusief de voor het publiek toegankelijke gangen,
                        sanitaire lokalen, inkomhal, … en alle vergelijkbare
                        ruimten. De oppervlakten van de aanhorigheden maken hier
                        GEEN deel van uit (ook niet als er via deze
                        aanhorigheden moet kunnen geëvacueerd worden). <br />
                        Oppervlakken die niet door publiek gebruikt worden (open
                        keuken, …) moeten niet meegerekend worden.
                    </p>
                    <h2>Vaste zitplaats:</h2>
                    <p>
                        Zitplaatsen, welke in de vloer of de wand van de
                        inrichting worden verankerd. Indien de zitplaatsen niet
                        individueel afgebakend zijn, wordt gerekend met een
                        zitplaatsbreedte van 50 cm per persoon.
                        <br />
                        <br />
                        <i>Toelichting:</i>
                        <br />
                        Een zitbank met een breedte van 120 cm is slechts
                        geschikt om plaats te bieden aan 2 personen.
                    </p>
                    <h2>Geaccrediteerde keuringsinstantie:</h2>
                    <p>
                        Keuringsorganisme, erkend door FOD Economie en
                        geaccrediteerd volgens ISO-17020.
                    </p>
                    <h2>Erkend organisme:</h2>
                    <p>
                        Keuringsorganisme, erkend door FOD Economie voor het
                        uitvoeren van controles op elektrische installaties.
                    </p>
                    <h2>Externe Dienst voor Technische Controle:</h2>
                    <p>
                        Keuringsorganisme, die voor erkenning en werking
                        beantwoorden aan Koninklijk besluit van 29 april 1999 en
                        instaan voor technische controles op de werkvloer van
                        o.a. hefwerktuigen zoals liften.
                    </p>
                    <h2>Bevoegd technicus:</h2>
                    <p>
                        Een persoon of organisatie met de nodige kennis, het
                        nodige materiaal, de nodige erkenning enz. om dergelijke
                        controles uit te voeren (bv. gasdichtheid, verwarming,
                        enz.).
                    </p>
                    <h2>Bevoegd persoon:</h2>
                    <p>
                        Een persoon die door de exploitant wordt aangeduid om
                        bepaalde taken uit te voeren. Deze persoon beschikt
                        daartoe over de nodige kennis en vaardigheden, alsook de
                        noodzakelijke middelen (d.w.z. materieel en tijd) om
                        deze taken uit te voeren.
                    </p>
                    <h2>Aantrede:</h2>
                    <p>
                        De horizontale projectie van de afstand tussen twee
                        opeenvolgende trapneuzen, gemeten op de looplijn.
                    </p>
                    <h2>Optrede:</h2>
                    <p>
                        De verticale projectie van de afstand tussen twee
                        opeenvolgende trapneuzen
                    </p>
                    <h2>Versiering:</h2>
                    <p>
                        Met versiering wordt niet de normale functionele
                        stoffering bedoeld zoals gordijnen en overgordijnen,
                        tafellinnen, vloerbekleding, behangpapier of stoffering
                        rechtstreeks of onrechtstreeks op de wanden of plafonds
                        aangebracht
                    </p>
                    <h2>Veiligheidsregister:</h2>
                    <p>
                        Een map met documenten. <br />
                        Deze map bevat informatie over veiligheidsvoorschriften
                        en vergunning waaronder (indien van toepassing)
                    </p>
                    <ul>
                        <li>Brandveiligheidsverslag en -attest;</li>
                        <li>Exploitatievergunning;</li>
                        <li>Milieuvergunning</li>
                        <li>Omgevingsvergunning</li>
                        <li>Aantal toegelaten personen conform B2;</li>
                        <li>Aantal toegelaten personen;</li>
                        <li>
                            Verslagen en/of attesten van de periodieke
                            controles;
                        </li>
                        <li>Opleidingen personeel;</li>
                        <li>
                            Verzekeringspolis objectieve aansprakelijkheid in
                            geval van brand en ontploffing.
                        </li>
                    </ul>
                    <p>
                        Voor het samenstellen van dit veiligheidsregister kan u
                        gebruik maken van dit <a href="https://www.brandweerzonerand.be/sites/default/files/bijlages/template_veiligheidsregister.docx">sjabloon</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TerminologiePage;
