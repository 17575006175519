import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import Tooltip from "../Tooltip";
import ThemeTooltip from "../ThemeTooltip";
import { getPropertyByCategory } from "../../services/propertyService";

const CategoryFormStep2 = ({ data, sessionProperties, handleChange }) => {
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        getPropertyByCategory(data.categoryNr).then((resp) => {
            setProperties(resp.data);
        });
    }, [data]);

    const propCheck = (id) => {
        if (sessionProperties) {
            if (
                sessionProperties.filter((prop) => Number(prop.id) === id)
                    .length > 0
            ) {
                return true;
            }
        }
    };

    function CheckTooltip(props) {
        if (props.tip){
            return <ThemeTooltip>test</ThemeTooltip>;
        }
        return "";
    }

    return (
        <div>
            <div className="formInput formInput--checkbox margin-bottom-xlg">
                <Tooltip>
                    Om een correct en volledig advies te verlenen hebben we bepaalde informatie over uw zaak nodig. Hier kan u aanduiden wat in jouw zaak van toepassing is.
                </Tooltip>

                <p>
                    Onderstaande stellingen zijn van toepassing op mijn pand.
                </p>

                {properties.map((property) => (
                    <label key={property.id} htmlFor={property.id}>
                        {property.title}
                        <CheckTooltip tip={property.tooltip} />
                        <input
                            type="checkbox"
                            name={property.id}
                            id={property.id}
                            onChange={handleChange}
                            checked={propCheck(property.id)}
                        />
                        <span className="checkmark">
                            <FaCheck />
                        </span>
                    </label>
                ))}
            </div>
        </div>
    );
};

export default CategoryFormStep2;
