const PageTitle = ({ title, subTitle, pageType, classes }) => {
    return (
        <h1 className={`page-title ${ classes } ${pageType === "basic" ? "page-title--lg" : ""}`}>
            { title }
            { subTitle &&
                <span className="page-title__sub"> { subTitle }</span>
            }
        </h1>
    )
}

export default PageTitle
